<template>
  <div>
    <content-card title="Resumen de orden" :class="{ 'quick-start-selected': $store.state.quickStart.step === 6 }">
      <quick-start-tip
        title="Resumen de la Orden"
        content="Puedes agregar anticipos que el cliente te haya dado, y asi llevar un mejor control del cobro.
        Tambien puedes agregar impuestos si asi lo necesitas."
        placement="left"
        :bg-purple="false"
        :value="$store.state.quickStart.step === 6"
        @onNextStep="handleNextStep">
        <div class="flex flex-col py-2">
          <div
            class="flex flex-row items-center py-2">
            <div>
              <sa-button
                type="text"
                customClass="underline"
                @click="showServicesBlock">
              <span>
                Servicios
              </span>
              </sa-button>
            </div>
            <div class="ml-auto">
              <span>{{ moneyFormat(subtotal) }}</span>
            </div>
          </div>
          <div
            class="flex flex-row items-center py-2">
            <div>
              <sa-button
                type="text"
                customClass="underline"
                @click="showAddTaxBlock">
                <span>
                  Impuestos
                </span>
              </sa-button>
            </div>
            <div class="ml-auto">
              <span>{{ moneyFormat(tax.taxAmount) }}</span>
            </div>
          </div>
          <div
            class="flex flex-row items-center py-2">
            <div>
              <sa-button
                type="text"
                customClass="underline"
                @click="showAddPaymentBlock">
              <span>
                Anticipos
              </span>
              </sa-button>
            </div>
            <div class="ml-auto">
              <span>{{ moneyFormat(advance * -1) }}</span>
            </div>
          </div>
          <div class="py-2 border-t">
            <div class="flex flex-row">
              <span class="font-bold">Total: </span>
              <span class="ml-auto font-bold">{{ moneyFormat(total) }}</span>
            </div>
          </div>
          <div class="pt-4 border-t" v-if="finalizedAt">
            <div class="flex flex-row items-center">
              <div class="w-full">
                <span class="font-bold">Fecha Entregado:</span>
              </div>
              <el-date-picker
                class="w-full"
                v-model="finalizedAt"
                type="date"
                :format="$store.state.business.dateFormat"
                disabled>
              </el-date-picker>
            </div>
          </div>
        </div>
      </quick-start-tip>
    </content-card>
    <el-dialog
      :visible.sync="isVisibleServicesBlock"
      :width="width">
      <div class="block w-full overflow-x-auto">
        <table class="items-center w-full border-collapse ">
          <thead>
            <tr class="px-2 border-b-2">
              <th class="py-3 font-bold text-left border-l-0 border-r-0 whitespace-nowrap">
                Tipo
              </th>
              <th class="py-3 font-bold text-left border-l-0 border-r-0 whitespace-nowrap">
                Nombre
              </th>
              <th class="py-3 font-bold text-left border-l-0 border-r-0 whitespace-nowrap">
                Descripcion
              </th>
              <th class="py-3 font-bold text-left border-l-0 border-r-0 whitespace-nowrap text-right">
                Precio
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="service in services"
              :key="service.id"
              class="cursor-pointer hover:bg-gray-100 hover:shadow">
              <td class="py-2 border-t" style="min-width: 150px; width: 150px;">
                {{ service.type }}
              </td>
              <td class="py-2 border-t" style="min-width: 150px; width: 150px;">
                {{ service.name }}
              </td>
              <td class="py-2 border-t" style="min-width: 250px; width: 250px;">
                {{ service.description }}
              </td>
              <td class="py-2 border-t text-right" style="min-width: 50px; width: 50px;">
                {{ moneyFormat(service.price) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="isVisibleAddPaymentBlock"
      :width="width"
      @close="isVisibleAddPaymentBlock = false">
      <advance-form-block
        @addAdvance="addAdvance"
        @changeAdvance="changeAdvance"
        @removeAdvance="removeAdvance"
        @close="isVisibleAddPaymentBlock = false" />
    </el-dialog>
    <el-dialog
      :visible.sync="isVisibleAddTaxBlock"
      :width="width">
      <tax-form-block
        :tax="tax"
        @addTax="addTax"
        @close="isVisibleAddTaxBlock = false" />
    </el-dialog>
  </div>
</template>
<script>
import { mutations, getters } from '../store';
import numbers from '@/utils/numbers';
import _ from 'lodash';
import responsiveSize from '@/mixins/responsiveSize';
import { QUICK_START_STORE } from '@/store/modules/quick-start/mutation-types';

export default {
  name: 'PaymentBlock',
  mixins: [responsiveSize],
  components: {
    ContentCard: () => import('@/components/molecules/ContentCard.vue'),
    AdvanceFormBlock: () => import('./AdvanceFormBlock.vue'),
    TaxFormBlock: () => import('./TaxFormBlock.vue'),
  },
  data() {
    return {
      width: '40%',
      isVisibleServicesBlock: false,
      isVisibleAddPaymentBlock: false,
      isVisibleAddTaxBlock: false,
    };
  },
  created() {
    window.addEventListener('resize', _.debounce(this.resize, 200));
  },
  destroyed() {
    window.removeEventListener('resize', _.debounce(this.resize, 200));
  },
  computed: {
    services() {
      return getters.getServices();
    },
    subtotal() {
      return getters.getSubtotal();
    },
    tax() {
      return getters.getTax();
    },
    advance() {
      return getters.getAdvance();
    },
    total() {
      return getters.getTotal();
    },
    finalizedAt() {
      return getters.getFinalizedStatusAt();
    },
  },
  methods: {
    resize() {
      const percentageWidth = this.getPercentageWidth();
      this.width = percentageWidth === '30%' ? '40%' : percentageWidth;
    },
    showServicesBlock() {
      this.isVisibleServicesBlock = true;
      this.resize();
    },
    showAddTaxBlock() {
      this.isVisibleAddTaxBlock = true;
      this.resize();
    },
    showAddPaymentBlock() {
      this.isVisibleAddPaymentBlock = true;
      this.resize();
    },
    addAdvance(advance) {
      mutations.addAdvance(advance);
    },
    changeAdvance(advance) {
      mutations.changeAdvance(advance);
    },
    removeAdvance(advanceId) {
      mutations.removeAdvance(advanceId);
    },
    addTax(tax) {
      mutations.changeTax(tax);
      this.isVisibleAddTaxBlock = false;
    },
    moneyFormat(value) {
      const { currencySymbol } = this.$store.state.account;
      return numbers.moneyFormat(value, currencySymbol);
    },
    handleNextStep() {
      const step = this.$store.state.quickStart.step;
      this.$store.dispatch(QUICK_START_STORE, { step: step + 1 });
    },
  },
}
</script>
